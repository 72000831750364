/* Define standard variables and values for website */
$bgcolor: rgba(173, 216, 230, 0.4);
$textcolor1: rgb(255, 255, 255);
$textcolor2: rgba(39, 85, 93, 1);


@font-face {
    font-family: 'houschka';
    src: url(../imports/font/HouschkaRoundedAlt-MediumItalic.ttf);
    font-weight: 500;
}

$Primary_color:rgba(226, 214, 206, 1);
$Secondary_color:rgba(205, 123, 85, 1);
$Third_color:rgba(210, 207, 233, 0.8);
$Fourth_color:rgba(39, 85, 93, 1);


$Font_size_title: 1.4rem;
$fontsize: 1.2rem;


/* Use the variables */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}


.Container {
    //background-color: $bgcolor;
    display: flex;
    //height: 82vh;
    align-items: center;
    justify-content: center;
    font-family: 'houschka';
    // background-image: url('../imports/images/IMG_20230319_143053.jpeg');
    // background-size: cover;
    // background-position: center;
    //overflow-y: scroll;
}

.subContainer {
    height: 82vh;
    //background-color: $Third_color;
    color: $textcolor2;
    font-size: $fontsize;
    max-width: 100%;
    font-family: 'houschka';
    display: flex;
    align-items: center;
    //justify-content: center;
    //margin-left: 200px;
    //margin-right: 200px;
}

.image{
    width: 50%;
    height: 82vh;
    align-items: center;
}

.image img{
    //max-width: 100%;
    padding-top: 25px;
    padding-bottom: 25px;
    margin-left: auto;
    margin-right: auto;
    height: inherit ;
    display: block;
}

.imageKine img{
    //width: 50%;
    height: 82vh;
    //align-items: center;
    //max-width: 100%;
    //height: inherit ;
    display: block;
}

.image_plain{
        img{
            display: block;
            object-fit: cover;
            max-width: 50vw;
            height: 82vh;
            //width: auto;
            //height: auto;
            // padding-top: 0px;
            // padding-bottom: 0px;
            //height: 100%;
            // width: inherit ;
            //display: block;
        }
    }

.content {
    width: 80%;
    padding-right: 35px;
    padding-left: 35px;
    // overflow-y: scroll;
    overflow-y: none;
    max-height: 82vh;
}

.text{
    
    height: 76vh;
    width: 100%;
    overflow-y: auto;
    //overflow-y: scroll;
}

h1 {
    //background-color: $bgcolor;
    color: $textcolor2;
    font-size: $Font_size_title;
    margin-bottom: 20px;
}

p {
    
    color: $textcolor2;
    font-size: $fontsize;
    margin-bottom: 30px;
}

nav {
    color: $textcolor2;
    font-size: $Font_size_title;
    background-color: $Fourth_color;
    display: flex;
    justify-content: center;
}
nav ul {
    display: flex;
    gap: 3rem;
    list-style: none;
}

.navBar{
    height: 12vh;
    display: flex;
    justify-content: space-between;
    font-size: $Font_size_title;
    background-color: $Fourth_color;
    align-items: center;
    font-family: 'houschka';
}

.navBar img {
    height: 12vh;
    margin-top: 8px;
    
}

a {
    color: $Primary_color;
    text-decoration: none;
}
// nav li:hover {
//     color:$Secondary_color;
// }

.footer {
    font-size: $Font_size_title;
    background-color: $Fourth_color;
    display: flex;
    height: 6vh;
    align-items: center;
    justify-content: center;
    justify-items: center;
    font-family: 'houschka';
    gap: 3rem;
    color: $textcolor2;
    font-size: $fontsize;
    color: $Primary_color;
}

.kinesioList li{
    list-style: none;
    margin-bottom: 2px;
}

.buttonDiv{
    list-style: none;
}

.button {
    background-color: $Secondary_color;
    padding: 10px;
    border: none;
    border-radius:15px;
    margin-right: 50px;
    color: $textcolor1;
    font-size: $fontsize;
    list-style: none;
}
.map{
    width: 80%;
    height: 400px;
}

nav .active{
    border-bottom: 1px solid $Secondary_color;
}

@media screen and (max-width: 1200px) {

    .reiki {
        object-position: top 0em right -9em ;
    }
    .kinesio {
        object-position: top 0em right -18em ;
    }
    .reflexo {
        object-position: top 0em right -15em ;
    }
    .tarifs {
        object-position: top 0em right -15em ;
    }
    .contact {
        object-position: top 0em right -18em ;
    }
}

@media screen and (max-width: 900px) {
    nav ul {
        display: flex;
        gap: 2rem;
        list-style: none;
    }

}
@media screen and (max-width: 700px) {

body{
    max-width: 100%;
    max-height: 100%;
    overflow-y: none;
    overflow-x: none;
}

h1{
    font-size:1.4rem;
}
p{
    font-size:1rem;
}

.buttonDiv{
    font-size:0.6rem;
    margin-right: 10px;
}

.footer{
    font-size:0.8rem;
}

.map{
    width: 100%;
    height: 300px;
}

.navBar{
    //height: 12vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: $Font_size_title;
    background-color: $Fourth_color;
    align-items: center;
    font-family: 'houschka';
}
    
.button {
    font-size:0.8rem;
    margin: 1rem;
}

// .textmedia{
//     height: 76vh;
//     width: 100%;
//     overflow-y: auto;
// }


nav ul {
    display: flex;
    //flex-direction: column;
    font-size: 1rem;
    gap: 1rem;
    list-style: none;
}


.image_plain{
    display: none;
    width: 0px;
    height: 0px;
}

.image{
    display: none;
    width: 0px;
    height: 0px;
}

.imageKine{
    display: none;
    width: 0px;
    height: 0px;
}

.content {
    max-width: 100vw;
    padding-right: 35px;
    padding-left: 35px;
    //overflow-y: auto;
    // overflow: hidden;
    max-height: 82vh;
    width: 90%;
}



}
  